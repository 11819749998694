/* eslint-disable no-console */

import React, { useEffect, useState, useContext } from 'react';
import { AnalyticsContext, trackEvent, PageTracking } from 'latitude-analytics';
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Select,
  TimeSpan,
  useBreakpoint,
  Flex
} from 'basis';
import Address from 'address-lookup/lib/components/address/Address';
import { PAGES } from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteReducerGem';
import { ProgressStepper } from '@/components/ProgressStepper/ProgressStepper';
import { PersonalLoanSoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/PersonalLoanSoftQuoteHeader';
import { incomeFromNzOptions } from '@/data/pages/soft-quote/incomeFromList';
import { residentialStatusOptions } from '@/data/pages/soft-quote/residentialStatusList';
import {
  ANALYTICS_SITESUBSECTION,
  COLOR,
  PADDING,
  PL_GEM_MAXIMUM_AMOUNT,
  PL_GEM_MINIMUM_AMOUNT
} from '@/utils/constants';
import ConfettiText from '@/components/ConfettiText/ConfettiText';
import { Box } from '@/components/Box/Box';
import { FormLayout } from '@/components/PersonalLoanSoftQuote/FormLayout';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {
  generateTemporaryToken,
  searchAddresses
} from '@/components/PersonalLoanSoftQuote/library/addressApi';
import { dataMapping } from '@/components/PersonalLoanSoftQuote/library/dataMapping';
import { setItemWithExpiry } from '@/utils/localStorageUtils';
import {
  consentPrivacyValidation,
  incomeFromValidation,
  residentialStatusValidation,
  timeAtCurrentAddressValidation,
  timeAtCurrentEmploymentValidation
} from '@/components/PersonalLoanSoftQuote/validator/employmentValidator';
import {
  postcodeAddressValidation,
  propertyNameValidation,
  residentialAddressErrorMessage,
  residentialAddressServerErrorMessage,
  streetNameAddressValidation,
  streetTypeValidation,
  unitAddressValidation
} from '@/components/PersonalLoanSoftQuote/validator/addressValidator';
import { ServerError } from '@/components/PersonalLoanSoftQuote/ServerError';
import { LoadingIconText } from '@/components/Loading/LoadingIconText/LoadingIconText';
import { productGem } from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import { streetTypeNzOptions } from '@/components/PersonalLoanSoftQuote/constants/streetTypeNZList';
import {
  GEM_MINIMUM_RESIDENCY_IN_MONTHS,
  PRODUCT,
  REGION,
  REQUEST_CHANNEL, RESIDENTIAL_ADDRESS_TYPE
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {
  cityAddressValidation,
  streetNumberValidation,
  suburbAddressValidation
} from '@/components/PersonalLoanSoftQuote/validator/gemValidator';
import { getEnvForDebug, getSFApiTimeout } from '@/utils/getUrlFromEnvUtil';
import { dataLayerOnEmploymentSubmit, dataLayerOnQuoteResponse } from '@/utils/dataLayerUtil';
import {quoteResponse, quoteSubmit} from '@/components/PersonalLoanSoftQuote/library/softQuoteProxyApi';
import {getLengthOfResidencyInMonths} from '@/components/PersonalLoanSoftQuote/utils/convertTimeStampToMonths';
import {onSelectAutoAddress} from "@/components/PersonalLoanSoftQuote/utils/onSelectAutoAddress";
import {isAutoAddressValidOnSubmit} from "@/components/PersonalLoanSoftQuote/utils/addressUtils";
import EmploymentGlobalStyle from '../partials/EmploymentGlobalStyle';
import EmploymentButtonContainer from '../partials/EmploymentButtonContainer';
import EmploymentWhiteCheckboxWrapper from '../partials/EmploymentWhiteCheckboxWrapper';
import tealiumConfig from '../../../../tealium';

const minimumResidencyInMonths = GEM_MINIMUM_RESIDENCY_IN_MONTHS;
const DEBUG = getEnvForDebug();
const region = REGION.NZ;
export function GemEmployment({ state, dispatch }) {
  let [analytics] = useContext(AnalyticsContext);
  analytics.siteData.page.siteCountry = 'nz';
  tealiumConfig.siteData.page.siteCountry = 'nz';

  const COUNTRY = region;
  const LOCAL_STORAGE_KEY = 'TOKEN';
  const TOKEN_EXPIRY = 60000 * 10;
  const [showLoader, setShowLoader] = useState(false);
  const [showServerError, setShowServerError] = useState(false);

  const [
    hasResidentialAddressAutoAddressError,
    setHasResidentialAddressAutoAddressError
  ] = useState(false);
  const [
    hasPreviousAddressAutoAddressError,
    setHasPreviousAddressAutoAddressError
  ] = useState(false);

  const [autoAddressErrorMessageForResidentialAddress, setAutoAddressErrorMessageForResidentialAddress] = useState(
    residentialAddressErrorMessage
  );
  const [autoAddressErrorMessageForPreviousAddress, setAutoAddressErrorMessageForPreviousAddress] = useState(
    residentialAddressErrorMessage
  );

  const [addressToken, setAddressToken] = useState('');
  const [widgetValues, setWidgetValues] = useState({});

  let showPurposeError = false;
  let showPurposeOtherError = false;
  let showAmountMinError = false;
  let showAmountMaxError = false;

  window.history.pushState(null, '', window.location.href);
  window.onpopstate = function() {
    dispatch({ type: PAGES.ABOUT_YOU, formValues: state.formValues });
  };
  window.onbeforeunload = function(e) {
    e.preventDefault();
    return '';
  };

  const onSubmit = ({ values, errors }) => {
    const screenWidth = window.innerWidth;
    const MIN_DESKTOP_SCREEN = 992;
    let loanAmountIsValid;

    setShowLoader(true);
    window.scroll(0, 0);

    if(DEBUG) console.log('values', values);

    let residentialAddressIsValid = isAutoAddressValidOnSubmit(values.residentialAddress, region);

    if (!residentialAddressIsValid && !hasResidentialAddressAutoAddressError) {
      setHasResidentialAddressAutoAddressError(true);
    }

    let lengthOfResidencyInMonths = getLengthOfResidencyInMonths(
      values.timeAtCurrentAddress
    );

    let previousAddressIsValid =
      lengthOfResidencyInMonths >= minimumResidencyInMonths
      || (lengthOfResidencyInMonths < minimumResidencyInMonths
      && isAutoAddressValidOnSubmit(values.previousAddress, region));

    if (!previousAddressIsValid && !hasPreviousAddressAutoAddressError) {
      setHasPreviousAddressAutoAddressError(true);
    }

    if (screenWidth >= MIN_DESKTOP_SCREEN) {
      if (widgetValues.purpose === 'purpose') {
        showPurposeError = true;
      }

      if (
        widgetValues.purpose === 'other' &&
        widgetValues.purposeOther === ''
      ) {
        showPurposeOtherError = true;
      }

      loanAmountIsValid =
        widgetValues.loanAmount >= PL_GEM_MINIMUM_AMOUNT &&
        widgetValues.loanAmount <= PL_GEM_MAXIMUM_AMOUNT;
      if (widgetValues.loanAmount < PL_GEM_MINIMUM_AMOUNT) {
        showAmountMinError = true;
      }

      if (widgetValues.loanAmount > PL_GEM_MAXIMUM_AMOUNT) {
        showAmountMaxError = true;
      }
    } else {
      loanAmountIsValid = true;
    }

    if (
      !showPurposeError &&
      !showPurposeOtherError &&
      loanAmountIsValid &&
      residentialAddressIsValid &&
      previousAddressIsValid &&
      Object.keys(errors).length === 0
    ) {
      const formattedData = dataMapping(values, REGION.NZ, PRODUCT.GEM);
      if (DEBUG) console.log(formattedData);
      // Submit form data
      quoteSubmit(formattedData, REQUEST_CHANNEL.GFCoNz)
        .then(response => {
          if (!response.data.success) {
            if (DEBUG) console.log(response.data.success);
            setShowLoader(false);
            setShowServerError(true);
          }
          const quoteId = response.data.id;
          if(DEBUG) console.log('quoteID', quoteId);

          dataLayerOnEmploymentSubmit(analytics, values, quoteId, formattedData);
          trackEvent(analytics, {
            category: 'quote',
            action: 'quote-submitted',
            location: 'Employment',
            label: 'Get rate'
          });

          if (quoteId) {
            // Wait 30 second and get quote response
            setTimeout(
              () =>
                quoteResponse(quoteId, REQUEST_CHANNEL.GFCoNz)
                  .then(response => {
                    values.quoteNumber = response.data.quoteNumber;
                    values.quoteRate = response.data.quoteRate;
                    values.quoteSecuredRate = response.data.quoteSecuredRate;
                    values.quoteStatus = response.data.quoteStatus;
                    values.encryptedQuoteID = response.data.encryptedQuoteID;
                    dataLayerOnQuoteResponse(analytics, values);

                    dispatch({type: PAGES.YOUR_QUOTE, formValues: values});
                    setShowLoader(false);
                  })
                  .catch(error => {
                    if (DEBUG) console.log(error);
                    setShowLoader(false);
                    setShowServerError(true);
                  }),
                  getSFApiTimeout()
            );
          }
        })
        .catch(error => {
          if (DEBUG) console.log(error);
          setShowLoader(false);
          setShowServerError(true);
        });
    } else {
      setShowLoader(false);
      let widgetErrors = document.querySelectorAll(
        '[class*=Error], [class*=error]'
      );

      if (widgetErrors.length > 0) {
        widgetErrors.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      }
      let ariaInvalid = document.querySelectorAll('[aria-invalid=true]');
      if (ariaInvalid.length > 0) {
        ariaInvalid.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  };

  const onBack = ({ values }) => {
    dispatch({ type: PAGES.ABOUT_YOU, formValues: values });
  };
  const onSelectCurrentAddressAutoAddress = selectedItem => {
    onSelectAutoAddress(state, RESIDENTIAL_ADDRESS_TYPE.Current, selectedItem, addressToken,
      setAutoAddressErrorMessageForResidentialAddress, setHasResidentialAddressAutoAddressError, region);
  };

  const onSelectPreviousAddressAutoAddress = selectedItem => {
    onSelectAutoAddress(state, RESIDENTIAL_ADDRESS_TYPE.Previous, selectedItem, addressToken,
      setAutoAddressErrorMessageForPreviousAddress, setHasPreviousAddressAutoAddressError, region);
  };

  useEffect(() => {
    generateTemporaryToken(REQUEST_CHANNEL.GFCoNz)
      .then(token => {
        setAddressToken(token.data.key);
        setItemWithExpiry(token.data.key, LOCAL_STORAGE_KEY, TOKEN_EXPIRY);
      })
      .catch(error => {
        if (DEBUG) console.log(error);
        setAutoAddressErrorMessageForResidentialAddress(residentialAddressServerErrorMessage);
        setAutoAddressErrorMessageForPreviousAddress(residentialAddressServerErrorMessage);
      });
  }, []);

  return (
    <FormLayout product={productGem}>
      <EmploymentGlobalStyle />
      {showLoader ? (
        <Box padding={PADDING.P16} width="100%" height="80vh">
          <Flex height="100%" placeItems="center">
            <LoadingIconText message="We're getting your rate for you" />
          </Flex>
        </Box>
      ) : showServerError ? (
        <PageTracking
          path={`/${ANALYTICS_SITESUBSECTION}/employment-server-error-gem`}
          exceptions={tealiumConfig.pageDataExceptions}
        >
          <ServerError hideTryAgain isGem />
        </PageTracking>
      ) : (
        <PageTracking
          path={`/${ANALYTICS_SITESUBSECTION}/employment-gem`}
          exceptions={tealiumConfig.pageDataExceptions}
        >
          <Form initialValues={state.formValues} onSubmit={onSubmit}>
            {({ state }) => {
              const lengthOfResidencyInMonths = getLengthOfResidencyInMonths(
                state.values.timeAtCurrentAddress
              );

              return (
                <Grid preset="page" rowsGap={4}>
                  <Grid.Item colSpan="all">
                    <PersonalLoanSoftQuoteHeader />
                  </Grid.Item>
                  <Grid.Item
                    colSpan="all"
                    colSpan-md="1-6"
                    colSpan-lg="1-10"
                    rowSpan="1"
                    rowSpan-lg="1"
                  >
                    <Container
                      bg="grey.t07"
                      padding="0"
                      margin="0 4"
                      margin-md="0"
                    >
                      <ProgressStepper currentLabel="Employment" />
                    </Container>
                  </Grid.Item>
                  <Grid.Item
                    colSpan="all"
                    colSpan-md="1-6"
                    colSpan-lg="1-6"
                    rowSpan="2"
                  >
                    <Container
                      bg="white"
                      padding="0"
                      margin="0 0 4 0"
                      margin-sm="0 4 4 4"
                      margin-md="0"
                    >
                      <Container
                        bg="grey.t07"
                        padding="4 4 4"
                        padding-sm="4"
                        margin="0 4"
                        margin-sm="0"
                      >
                        <Grid rowsGap={6}>
                          <Address
                            name="residentialAddress"
                            values={state.values.residentialAddress}
                            label="Current residential address"
                            allowPO={false}
                            testId="current-residential-address"
                            onSelect={onSelectCurrentAddressAutoAddress}
                            generateTemporaryToken={generateTemporaryToken}
                            searchAddresses={searchAddresses}
                            country={COUNTRY}
                            allowPropertyName
                            autoAddressError={
                              hasResidentialAddressAutoAddressError
                            }
                            addressValidator={{
                              postcodeAddressValidation: postcodeAddressValidation,
                              streetTypeValidation: streetTypeValidation,
                              streetNameAddressValidation: streetNameAddressValidation,
                              unitAddressValidation: unitAddressValidation,
                              streetNumberAddressValidation: streetNumberValidation(
                                state.values.residentialAddress.unitNumber
                              ),
                              suburbAddressValidation: suburbAddressValidation,
                              propertyNameValidation: propertyNameValidation,
                              cityAddressValidation: cityAddressValidation
                            }}
                            streetTypeOptions={streetTypeNzOptions}
                            autoAddressErrorMessage={autoAddressErrorMessageForResidentialAddress}
                          />

                          <TimeSpan
                            name="timeAtCurrentAddress"
                            label="Time at current address"
                            testId="time-at-current-address"
                            validate={timeAtCurrentAddressValidation}
                          />
                          {lengthOfResidencyInMonths <
                            minimumResidencyInMonths && (
                            <Address
                              name="previousAddress"
                              values={state.values.previousAddress}
                              label="Previous address"
                              allowPO={false}
                              testId="previous-address"
                              onSelect={onSelectPreviousAddressAutoAddress}
                              generateTemporaryToken={generateTemporaryToken}
                              searchAddresses={searchAddresses}
                              country={COUNTRY}
                              allowPropertyName
                              autoAddressError={
                                hasPreviousAddressAutoAddressError
                              }
                              addressValidator={{
                                postcodeAddressValidation: postcodeAddressValidation,
                                streetTypeValidation: streetTypeValidation,
                                streetNameAddressValidation: streetNameAddressValidation,
                                unitAddressValidation: unitAddressValidation,
                                streetNumberAddressValidation: streetNumberValidation(
                                  state.values.previousAddress.unitNumber
                                ),
                                suburbAddressValidation: suburbAddressValidation,
                                propertyNameValidation: propertyNameValidation,
                                cityAddressValidation: cityAddressValidation
                              }}
                              streetTypeOptions={streetTypeNzOptions}
                              autoAddressErrorMessage={autoAddressErrorMessageForPreviousAddress}
                            />
                          )}
                          <Select
                            name="residentialStatus"
                            label="Residential status"
                            options={residentialStatusOptions}
                            testId="residential-status"
                            validate={residentialStatusValidation}
                          />
                          <Select
                            name="incomeFrom"
                            label="Income from"
                            options={incomeFromNzOptions}
                            testId="income-from"
                            validate={incomeFromValidation}
                          />
                          {(state.values.incomeFrom === 'Net Salary/Wages' ||
                            state.values.incomeFrom === 'Self Employed') && (
                            <TimeSpan
                              name="timeAtCurrentEmployment"
                              label="Time at current employment"
                              testId="time-at-current-employment"
                              optional={
                                !state.values.incomeFrom ===
                                  'Net Salary/Wages' ||
                                !state.values.incomeFrom === 'Self Employed'
                              }
                              validate={timeAtCurrentEmploymentValidation}
                            />
                          )}
                          <EmploymentWhiteCheckboxWrapper>
                            <Checkbox
                              name="consentPrivacy"
                              label="I confirm that by clicking 'Get rate':"
                              validate={consentPrivacyValidation}
                            >
                              I have read and and agreed to the{' '}
                              <a
                                href="https://lfs-apply.mypersonalloanapp.com/gem/APP_PrivacyPolicy"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  trackEvent(analytics, {
                                    category: 'quote',
                                    action: 'internal-link',
                                    location: 'Employment',
                                    label: 'Important Privacy Notice'
                                  });
                                }}
                              >
                                Important Privacy Notice
                              </a>
                            </Checkbox>

                            <Box
                              margin="15px auto 0"
                              paddingLeft={PADDING.P8}
                              paddingRight={PADDING.P8}
                              css="max-width:415px;"
                            >
                              <ConfettiText
                                text="Click ‘Get rate’ for a personalised interest rate and repayment amount"
                                backgroundColor={`${COLOR.TRANSPARENT}`}
                              />
                            </Box>

                            <Container margin="4 0 0">
                              <EmploymentButtonContainer>
                                <Button
                                  variant="secondary"
                                  width="50%"
                                  onClick={() => {
                                    trackEvent(analytics, {
                                      category: 'quote',
                                      action: 'quote-navigation',
                                      location: 'Employment',
                                      label: 'Back'
                                    });
                                    onBack(state);
                                  }}
                                  testId="btn-back"
                                >
                                  Back
                                </Button>
                                <Button
                                  type="submit"
                                  width="50%"
                                  color="green"
                                  margin="0 0 0 4"
                                  testId="btn-get-rate"
                                >
                                  Get rate
                                </Button>
                              </EmploymentButtonContainer>
                            </Container>
                          </EmploymentWhiteCheckboxWrapper>
                        </Grid>
                      </Container>
                    </Container>
                  </Grid.Item>
                  <Grid.Item
                    colSpan="all"
                    colSpan-md="1-6"
                    colSpan-lg="7-10"
                    rowSpan="1"
                    rowSpan-lg="2"
                  >
                    {['lg', 'xl'].includes(useBreakpoint()) && (
                      <Container margin="4" margin-sm="0 4" margin-md="0">
                        <EstimateRateWidget
                          quote
                          showPurposeError={showPurposeError}
                          setWidgetValues={setWidgetValues}
                          showPurposeOtherError={showPurposeOtherError}
                          showAmountMinError={showAmountMinError}
                          showAmountMaxError={showAmountMaxError}
                          isGem
                        />
                      </Container>
                    )}
                  </Grid.Item>
                </Grid>
              );
            }}
          </Form>
        </PageTracking>
      )}
    </FormLayout>
  );
}
