import isPast from 'date-fns/isPast'
import {isValid as isDateValid, parseISO} from 'date-fns';
import {
  streetNumberAddressRegex,
  validateSelect,
  validateWithLength
} from '@/components/PersonalLoanSoftQuote/validator/addressValidator';
import {DAY_REGEX, MONTH_REGEX, YEAR_REGEX} from '@/components/PersonalLoanSoftQuote/validator/aboutYouValidator';

const nzDriverLicence = /^[a-zA-Z][a-zA-Z]\d\d\d\d\d\d$/;
const nzDriverLicenceVersion = /^\d\d\d$/;
const nzMobileExpr = /^02[0-9]{7,9}$/;
const cityAddressRegex = RegExp("^[A-Za-z '-]*$");
const suburbRuralDeliveryNumberAddressRegex = RegExp("^[A-Za-z0-9 -]*$");

export const hasDriverLicenceValidation = validateSelect(
  `Select an option`,
);
export function validateNzMobile() {
  return value => {
    const auMobileLength = value.trim().length;

    if (auMobileLength > 11 || auMobileLength < 9 || !nzMobileExpr.test(value)) {
      return 'Enter a 9-11-digit mobile phone number starting with 02, no spaces';
    }

    return null;
  };
}

export function nzDriverLicenceValidation(hasDriverLicence) {
  return value => {
    if (!hasDriverLicence === 'yes') {
      return null;
    }
    const driverLicenceLength = value.trim().length;
    if (driverLicenceLength === 0) {
      return "Enter a valid 8 character driver licence, no spaces";
    }
    if (!nzDriverLicence.test(value)) {
      return "Enter a valid 8 character driver licence, no spaces. First two characters are letters, last six are numbers (eg. AB123456)";
    }

    return null;
  };
}

export function nzDriverLicenceVersionValidation(hasDriverLicence) {
  return value => {
    if (!hasDriverLicence === 'yes') {
      return null;
    }
    if (!nzDriverLicenceVersion.test(value)) {
      return "Enter a 3 digit version number";
    }

    return null;
  };
}

export const licenceExpiryDateValidation = ({ day, month, year }) => {
  const errors = [];
  if (DAY_REGEX.test(day)) {
    const dayInt = parseInt(day, 10);

    if (dayInt < 1 || dayInt > 31) {
      errors.push("Day must be within 1-31");
    }
  } else {
    errors.push("Enter a valid day");
  }

  if (MONTH_REGEX.test(month)) {
    const monthInt = parseInt(month, 10);

    if (monthInt < 1 || monthInt > 12) {
      errors.push("Month must be within 1-12");
    }
  } else {
    errors.push("Enter a valid month");
  }

  if (!YEAR_REGEX.test(year)) {
    errors.push("Enter a valid year");
  }
  const twoDigitsDay = day.length === 1 ? `0${day}` : day;
  const twoDigitsMonth = month.length === 1 ? `0${month}` : month;
  const formattedDate = `${year}-${twoDigitsMonth}-${twoDigitsDay}`;

  const hasPassed = isPast(new Date(formattedDate));

  if (hasPassed) {
    errors.push('Licence must expire after today');
  }

  if (
    isDateValid(
      parseISO(
        `${
          year
        }-${twoDigitsMonth}-${twoDigitsDay}`
      )
    ) === false
  ) {
    errors.push("Invalid date.");
  }

  return errors.length > 0 ? errors :  null;
};

export function streetNumberValidation(unitNumber) {
  return value => {
    const unitNumberLength = unitNumber.trim().length;
    const streetNumberLength = value.trim().length;
    const totalUnitAndStreetNumberLength = parseInt(unitNumberLength) + parseInt(streetNumberLength);
    if (streetNumberLength === 0) {
      return "Enter a street number between 1-7 characters";
    }
    if (totalUnitAndStreetNumberLength > 7) {
      return "Enter a street number between 1-7 characters total for Unit number and Street number";
    }
    if (!streetNumberAddressRegex.test(value)) {
      return "Enter a valid street number";
    }

    return null;
  };
}

function validateSuburb({ minLength, maxLength, fieldName, regex, invalidMessage }) {
  return value => {
    const fieldLength = value.trim().length;
    const errors = [];

    if (fieldLength === 0) {
      return null;
    }

    if ((minLength && fieldLength < minLength) || (maxLength && fieldLength > maxLength)) {
      errors.push(`Enter a ${fieldName} between ${minLength}-${maxLength} characters`);
    }

    if (!regex.test(value)) {
      errors.push(invalidMessage);
    }
    return errors.length > 0 ? errors :  null;
  };
}

export const nzMobileValidation = validateNzMobile();
export const suburbAddressValidation = validateSuburb({
  minLength: 1,
  maxLength: 22,
  fieldName: 'suburb/rural delivery number',
  regex: suburbRuralDeliveryNumberAddressRegex,
  invalidMessage: 'Enter a valid suburb/rural delivery number'
});

export const cityAddressValidation = validateWithLength({
  minLength: 1,
  maxLength: 22,
  fieldName: 'town/city',
  regex: cityAddressRegex,
  invalidMessage: 'Enter a valid town/city'
});
