/* eslint-disable no-console */

import React, { useEffect, useState, useContext } from 'react';
import {
  AnalyticsContext,
  trackEvent,
  PageTracking,
} from 'latitude-analytics';
import {
  Button,
  Container,
  DatePicker,
  Flex,
  Form,
  Grid,
  Input,
  LoadingIcon,
  RadioGroup,
  Select,
  Text
} from 'basis';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import styled from '@emotion/styled';
import { Link as ButtonLink } from '@latitude/link';
import { Box } from '@latitude/box';
import queryString from 'query-string';
import { ProgressStepper } from '@/components/ProgressStepper/ProgressStepper';
import { PersonalLoanSoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/PersonalLoanSoftQuoteHeader';
import { titleOptions } from '@/data/pages/soft-quote/titleList';
import { PAGES } from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteReducerGem';
import { FormLayout } from '@/components/PersonalLoanSoftQuote/FormLayout';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {
  ANALYTICS_SITESUBSECTION,
  PADDING,
  PL_GEM_DEFAULT_PERIOD,
  PL_GEM_MAXIMUM_AMOUNT,
  PL_GEM_MINIMUM_AMOUNT,
  SOFT_QUOTE_NZ_RETRIEVE_URL,
  SOFT_QUOTE_NZ_URL
} from '@/utils/constants';
import ReferredMessage from '@/components/PersonalLoanSoftQuote/ReferredMessage';
import { NonFormLayout } from '@/components/PersonalLoanSoftQuote/NonFormLayout';
import {
  dateOfBirthValidation,
  emailValidation,
  firstNameValidation,
  genderValidation,
  lastNameValidation,
  middleNameValidation,
  titleValidation
} from '@/components/PersonalLoanSoftQuote/validator/aboutYouValidator';
import { ServerError } from '@/components/PersonalLoanSoftQuote/ServerError';
import { Eligibility } from '@/components/PersonalLoanSoftQuote/Eligibility';
import { productGem } from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import {
  CHANNEL,
  REGION,
  REQUEST_CHANNEL
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {
  hasDriverLicenceValidation,
  licenceExpiryDateValidation,
  nzDriverLicenceValidation,
  nzDriverLicenceVersionValidation,
  nzMobileValidation
} from '@/components/PersonalLoanSoftQuote/validator/gemValidator';
import {getEnvForDebug, getUrlFromEnv} from '@/utils/getUrlFromEnvUtil';
import {dataLayerOnAboutSubmit, dataLayerOnFirstPageLoad, dataLayerOnAboutState, dataLayerOnAboutYouReferred} from '@/utils/dataLayerUtil';
import {checkForDuplicateQuote} from '@/components/PersonalLoanSoftQuote/library/softQuoteProxyApi';
import tealiumConfig from '../../../../tealium';

const RadioButtonWrapper = styled.div`
  div[role='radio'] label {
    margin-bottom: 0 !important;
  }
`;

const PinkInfoBanner = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #f682bc;
  background-color: #ffeff7;
  padding: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT.XS}) {
    align-items: center;
    flex-flow: column-reverse;
    width: 100%;
  }
  a {
    width: calc(50% - 8px);
    @media (max-width: ${BREAKPOINT.SM}) {
      width: calc(50% - 4px);
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: ${BREAKPOINT.XS}) {
      width: 160px;
    }
  }
  a:nth-child(1) {
    @media (max-width: ${BREAKPOINT.XS}) {
      margin-top: 12px !important;
    }
  }
`;

const WidgetContainer = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    /* allows widget to overflow for correct spacing */
    height: 200px;
  }
`;
const DEBUG = getEnvForDebug();
export function GemAboutYou({ state, dispatch }) {
  let [analytics] = useContext(AnalyticsContext);
  analytics.siteData.page.siteCountry = 'nz';
  tealiumConfig.siteData.page.siteCountry = 'nz';

  dataLayerOnFirstPageLoad(analytics);

  const DUPLICATE_QUOTE_NOT_EXISTS_DESCRIPTION = 'Quote_does_not_exists';
  const DUPLICATE_QUOTE_EXISTS_DESCRIPTION = 'Quote_Already_exists';
  const QUOTE_STATUS_REFERRED = 'Referred';
  const QUOTE_STATUS_DECLINED = 'Declined';

  const [showLoader, setShowLoader] = useState(false);
  const [showReferred, setShowReferred] = useState(false);
  const [widgetValues, setWidgetValues] = useState({});
  const [showServerError, setShowServerError] = useState(false);
  const [loadWidget, setLoadWidget] = useState(false);

  let showPurposeError = false;
  let showPurposeOtherError = false;
  let showAmountMinError = false;
  let showAmountMaxError = false;

  let loanAmount, purpose, repaymentPeriod, purposeOther;

  const [referredScreenName, setReferredScreenName] = useState('');

  useEffect(() => {
    let queryParams;
    if (typeof window !== 'undefined') {
      queryParams = queryString.parse(location.search);
      loanAmount = queryParams && queryParams.amount;
      purpose = queryParams && queryParams.purpose;
      repaymentPeriod = queryParams && queryParams.period;
      purposeOther = queryParams && queryParams.other;

      if (queryParams.amount) {
        sessionStorage.setItem(
          'loanAmount',
          loanAmount ?? PL_GEM_MINIMUM_AMOUNT
        );
        sessionStorage.setItem('purpose', purpose ?? 'purpose');
        sessionStorage.setItem('repaymentPeriod', repaymentPeriod ?? '5');
        if (purpose === 'other' && purposeOther) {
          sessionStorage.setItem('purposeOther', purposeOther);
        }
      } else if (!queryParams.amount && state.formValues.firstName === '') {
        sessionStorage.setItem('loanAmount', PL_GEM_MINIMUM_AMOUNT);
        sessionStorage.setItem('purpose', 'purpose');
        sessionStorage.setItem('repaymentPeriod', PL_GEM_DEFAULT_PERIOD);
      }
      setLoadWidget(true);
    }

    const queryParams1 = new URLSearchParams(location.search);
    if (queryParams1.has('amount')) {
      window.history.replaceState(
        {},
        SOFT_QUOTE_NZ_URL,
        SOFT_QUOTE_NZ_URL );
    }
  }, []);

  const onSubmit = ({ values, errors }) => {
    const minLoanAmount = PL_GEM_MINIMUM_AMOUNT;
    if (widgetValues.purpose === 'purpose') {
      showPurposeError = true;
    }

    if (widgetValues.purpose === 'other' && widgetValues.purposeOther === '') {
      showPurposeOtherError = true;
    }

    if (widgetValues.loanAmount < minLoanAmount) {
      showAmountMinError = true;
    }

    if (widgetValues.loanAmount > PL_GEM_MAXIMUM_AMOUNT) {
      showAmountMaxError = true;
    }

    if (values.hasDriverLicence === 'no') {
      delete errors['licenceNumber'];
      delete errors['expiryDate'];
      delete errors['version'];
    }

    if (
      !showPurposeError &&
      !showPurposeOtherError &&
      widgetValues.loanAmount >= minLoanAmount &&
      widgetValues.loanAmount <= PL_GEM_MAXIMUM_AMOUNT &&
      Object.keys(errors).length === 0
    ) {
      dataLayerOnAboutSubmit(analytics, widgetValues, values );
      if(DEBUG) console.log(analytics);
      trackEvent(analytics, {
        category: 'quote',
        action: 'quote-navigation',
        location: 'About',
        label: 'Next'
      });
      const duplicateCheckData = {
        emailAddress: values.email,
        mobileNumber: values.mobileNumber,
        dateOfBirth: `${values.dateOfBirth.day.padStart(2, '0')}/${values.dateOfBirth.month.padStart(2, '0')}/${values.dateOfBirth.year}`
      };
      setShowLoader(true);
      window.scroll(0, 0);

      checkForDuplicateQuote(
        duplicateCheckData,
        REQUEST_CHANNEL.GFCoNz
      )
        .then(response => {
          let responseDescription = response.data.responseDescription;
          let quoteStatus = response.data.quoteStatus;

          if (DUPLICATE_QUOTE_NOT_EXISTS_DESCRIPTION === responseDescription) {
            dispatch({ type: PAGES.EMPLOYMENT, formValues: values });
            setShowLoader(false);
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            QUOTE_STATUS_REFERRED === quoteStatus
          ) {
            setReferredScreenName(values.firstName);
            setShowLoader(false);
            dataLayerOnAboutYouReferred(analytics)
            setShowReferred(true);
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            QUOTE_STATUS_DECLINED === quoteStatus
          ) {
            setShowLoader(false);
            dispatch({ type: PAGES.EMPLOYMENT, formValues: values });
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            (quoteStatus !== QUOTE_STATUS_REFERRED && quoteStatus !== QUOTE_STATUS_DECLINED)
          ) {
            window.location.href =
              `${SOFT_QUOTE_NZ_RETRIEVE_URL}?duplicate-check`;
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          if(DEBUG) console.log('error on duplicate quote', error);
          setShowLoader(false);
          setShowServerError(true);
        });
    } else {
      let widgetErrors = document.querySelectorAll(
        '[class*=Error], [class*=error]'
      );

      let purposeSelect = document.querySelectorAll('select#purpose');
      let purposeOtherSelect = document.querySelectorAll('input#other-purpose');
      let ariaInvalid = document.querySelectorAll('[aria-invalid=true]');
      let loanAmountInput = document.querySelectorAll(
        'input.currency-input__field'
      );

      if (showPurposeError) {
        purposeSelect.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (showPurposeOtherError) {
        purposeOtherSelect.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (showAmountMinError || showAmountMaxError) {
        loanAmountInput.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (widgetErrors.length > 0) {
        widgetErrors.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (ariaInvalid.length > 0) {
        ariaInvalid.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  };

  return showLoader ? (
    <NonFormLayout product={productGem}>
      <Box padding={PADDING.P16} width="100%" height="80vh">
        <Flex height="100%" placeItems="center">
          <LoadingIcon size="medium" />
        </Flex>
      </Box>
    </NonFormLayout>
  ) : showReferred ? (
    <PageTracking
      path={`/${ANALYTICS_SITESUBSECTION}/about-you-referred-gem`}
      exceptions={tealiumConfig.pageDataExceptions}
    >
      <NonFormLayout product={productGem}>
        <ReferredMessage name={referredScreenName} isGem />
      </NonFormLayout>
    </PageTracking>
  ) : showServerError ? (
    <PageTracking
      path={`/${ANALYTICS_SITESUBSECTION}/about-you-server-error-gem`}
      exceptions={tealiumConfig.pageDataExceptions}
    >
      <NonFormLayout product={productGem}>
        <ServerError hideTryAgain isGem />
      </NonFormLayout>
    </PageTracking>
  ) : (
      <FormLayout product={productGem}>
        <Form initialValues={state.formValues} onSubmit={onSubmit}>
          {({ state }) => {
            return (
              dataLayerOnAboutState(analytics, state, REGION.NZ) &&
              <Grid preset="page" rowsGap={4}>
                <Grid.Item colSpan="all">
                  <PersonalLoanSoftQuoteHeader />
                </Grid.Item>
                <Grid.Item
                  colSpan="all"
                  colSpan-md="1-6"
                  colSpan-lg="1-10"
                  rowSpan="1"
                  rowSpan-lg="1"
                >
                  <Container
                    bg="grey.t07"
                    padding="0"
                    margin="0 4"
                    margin-md="0"
                  >
                    <ProgressStepper currentLabel="About" />
                  </Container>
                </Grid.Item>
                <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="1-6">
                  <Container
                    bg="white"
                    padding="0"
                    margin="0 4"
                    margin-sm="0 4"
                    margin-md="0"
                  >
                    <Eligibility channel={CHANNEL.GEM} />
                  </Container>
                </Grid.Item>

                <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="7-10">
                  <Container margin="0 4 0" margin-md="0">
                    <WidgetContainer>
                      {loadWidget && (
                        <EstimateRateWidget
                          quote
                          showPurposeError={showPurposeError}
                          setWidgetValues={setWidgetValues}
                          showPurposeOtherError={showPurposeOtherError}
                          showAmountMinError={showAmountMinError}
                          showAmountMaxError={showAmountMaxError}
                          isGem
                        />
                      )}
                    </WidgetContainer>
                  </Container>
                </Grid.Item>

                <Grid.Item
                  colSpan="all"
                  colSpan-md="1-6"
                  colSpan-lg="1-6"
                  rowSpan="4"
                  rowSpan-lg="3"
                >
                  <Container
                    bg="white"
                    padding="0"
                    margin="0 4"
                    margin-sm="0 4"
                    margin-md="0"
                  >
                    <Container bg="grey.t07" padding="4" margin="0">
                      <Grid rowsGap={6}>
                        {/* Siblings are customers who already have a Personal Loan with Latitude */}
                        <RadioGroup
                          name="sibling"
                          label="Do you have a Gem Personal Loan?"
                          options={[
                            {
                              label: 'No',
                              value: 'no'
                            },
                            {
                              label: 'Yes',
                              value: 'yes'
                            }
                          ]}
                        />
                        {state.values.sibling === 'yes' && (
                          <PageTracking
                            path={`/${ANALYTICS_SITESUBSECTION}/about-you-sibling-gem`}
                            exceptions={tealiumConfig.pageDataExceptions}
                          >
                          <>
                            <Text>
                              Our Personal Loans are tailored to you, which
                              means that we take into consideration the
                              current loan you have with us, before we can
                              provide you with an interest rate. You can
                              either proceed to &apos;Apply Now&apos; below
                              or call us on the number below to discuss your
                              needs.
                            </Text>
                            <ButtonContainer>
                              <ButtonLink
                                button="tertiary"
                                href="tel:0800422898"
                                trackEventData={{
                                  location: 'About-Existing Loan'
                                }}
                              >
                                Call 0800 422 898
                              </ButtonLink>
                              <ButtonLink
                                button="primary"
                                href={getUrlFromEnv('pl-apply-url-gem')}
                                trackEventData={{
                                  category: 'cta',
                                  action: 'discovery-link',
                                  location: 'About-Existing Loan'
                                }}
                                trackId="btn-existing-loan"
                              >
                                Apply Now
                              </ButtonLink>
                            </ButtonContainer>
                          </>
                          </PageTracking>
                        )}
                        {state.values.sibling === 'no' && (
                          <>
                            <RadioGroup
                              name="numberOfPeopleRequesting"
                              label="How many people are requesting a quote?"
                              options={[
                                {
                                  label: '1 person',
                                  value: '1 person'
                                },
                                {
                                  label: '2 people',
                                  value: '2 people'
                                }
                              ]}
                            />
                            {state.values.numberOfPeopleRequesting ===
                              '1 person' && (
                              <>
                                <PinkInfoBanner>
                                  <Text>
                                    <strong>Important:</strong> Please enter
                                    your full legal name and date of birth
                                    as shown on your driver licence or
                                    passport as these details will help us
                                    verify you.
                                  </Text>
                                </PinkInfoBanner>
                                <Select
                                  name="title"
                                  label="Title"
                                  options={titleOptions}
                                  testId="title"
                                  validate={titleValidation}
                                />
                                <Input
                                  name="firstName"
                                  label="First name"
                                  testId="first-name"
                                  placeholder="Legal name as shown on your ID"
                                  validate={firstNameValidation}
                                />
                                <Input
                                  name="middleName"
                                  label="Middle name"
                                  testId="middle-name"
                                  placeholder="If you have one"
                                  validate={middleNameValidation}
                                />
                                <Input
                                  name="lastName"
                                  label="Last name"
                                  testId="last-name"
                                  placeholder="Legal name as shown on your ID"
                                  validate={lastNameValidation}
                                />
                                <RadioButtonWrapper>
                                  <RadioGroup
                                    name="gender"
                                    label="Gender"
                                    options={[
                                      {
                                        label: 'Male',
                                        value: 'Male'
                                      },
                                      {
                                        label: 'Female',
                                        value: 'Female'
                                      }
                                    ]}
                                    validate={genderValidation}
                                  />
                                </RadioButtonWrapper>
                                <DatePicker
                                  name="dateOfBirth"
                                  label="Date of birth"
                                  testId="date-of-birth"
                                  validate={dateOfBirthValidation}
                                />
                                <PinkInfoBanner>
                                  <Text>
                                    <strong>Important:</strong> Please enter
                                    your correct email and mobile number as
                                    this will be used to start your
                                    application, or to simply review your
                                    quote again.
                                  </Text>
                                </PinkInfoBanner>
                                <Input
                                  name="email"
                                  type="email"
                                  label="Email address"
                                  testId="email-address"
                                  helpText="This is the email we’ll send your rate quote to"
                                  validate={emailValidation}
                                />
                                <Input
                                  name="mobileNumber"
                                  variant="numeric"
                                  label="Mobile number"
                                  testId="mobile-number"
                                  validate={nzMobileValidation}
                                />
                                <RadioButtonWrapper>
                                  <RadioGroup
                                    name="hasDriverLicence"
                                    label="Do you have a NZ Driver Licence?"
                                    options={[
                                      {
                                        label: 'No',
                                        value: 'no'
                                      },
                                      {
                                        label: 'Yes',
                                        value: 'yes'
                                      }
                                    ]}
                                    validate={hasDriverLicenceValidation}
                                  />
                                </RadioButtonWrapper>
                                {state.values.hasDriverLicence ===
                                  'yes' && (
                                  <>
                                    <Input
                                      name="licenceNumber"
                                      label="Driver Licence number"
                                      testId="licence-number"
                                      validate={
                                        state.values.hasDriverLicence ===
                                          'yes' &&
                                        nzDriverLicenceValidation(
                                          state.values.hasDriverLicence
                                        )
                                      }
                                      disabled={
                                        !state.values.hasDriverLicence ===
                                        'yes'
                                      }
                                    />
                                    <Input
                                      name="version"
                                      label="Version"
                                      testId="version"
                                      variant="numeric"
                                      validate={
                                        state.values.hasDriverLicence ===
                                          'yes' &&
                                        nzDriverLicenceVersionValidation(
                                          state.values.hasDriverLicence
                                        )
                                      }
                                      disabled={
                                        !state.values.hasDriverLicence ===
                                        'yes'
                                      }
                                    />
                                    <DatePicker
                                      name="expiryDate"
                                      label="Expiry date"
                                      testId="expiry-date"
                                      validate={
                                        state.values.hasDriverLicence ===
                                          'yes' &&
                                        licenceExpiryDateValidation
                                      }
                                      disabled={
                                        !state.values.hasDriverLicence ===
                                        'yes'
                                      }
                                    />
                                  </>
                                )}
                                <Button
                                  type="submit"
                                  color="green"
                                  testId="btn-next"
                                >
                                  Next
                                </Button>
                              </>
                            )}

                            {state.values.numberOfPeopleRequesting ===
                              '2 people' && (
                              <PageTracking
                                path={`/${ANALYTICS_SITESUBSECTION}/about-you-joint-gem`}
                                exceptions={tealiumConfig.pageDataExceptions}
                              >
                              <>
                                <Text>
                                  At this stage, we can only provide single{' '}
                                  quotes. You can either start a Joint
                                  Personal Loan Application or call us if
                                  you have any questions.
                                </Text>
                                <ButtonContainer>
                                  <ButtonLink
                                    button="tertiary"
                                    href="tel:0800422898"
                                    trackEventData={{
                                      location: 'About-Joint application'
                                    }}
                                  >
                                    Call 0800 422 898
                                  </ButtonLink>
                                  <ButtonLink
                                    button="primary"
                                    href={getUrlFromEnv('pl-apply-url-gem')}
                                    trackEventData={{
                                      category: 'cta',
                                      action: 'discovery-link',
                                      location: 'About-Joint application'
                                    }}
                                    trackId="btn-joint-application"
                                  >
                                    Joint application
                                  </ButtonLink>
                                </ButtonContainer>
                              </>
                              </PageTracking>
                            )}
                          </>
                        )}
                      </Grid>
                    </Container>
                  </Container>
                </Grid.Item>
              </Grid>
            );
          }}
        </Form>
      </FormLayout>
  );
}
