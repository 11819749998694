import React from 'react';
import { PageTracking } from 'latitude-analytics';
import { NonFormLayout } from '@/components/PersonalLoanSoftQuote/NonFormLayout';
import { SoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/Header';
import { SectionLayout } from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuote';
import { SimplifiedFooter } from '@/components/PersonalLoanSoftQuote/SimplifiedFooter';
import { productGem } from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import { SALESFORCE_STATUS } from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import { QuoteDeclinedSection } from '@/components/PersonalLoanSoftQuote/QuoteDeclinedSection';
import { ANALYTICS_SITESUBSECTION } from '@/utils/constants';
import ApprovedMessage from '../ApprovedMessage';
import ReferredMessage from '../ReferredMessage';
import tealiumConfig from '../../../../tealium';

export function GemYourQuote({ state, queryString }) {
  const quoteStatus = state.formValues.quoteStatus;
  const interestRate = state.formValues.quoteRate;
  const securedInterestRate = state.formValues.quoteSecuredRate;
  const quoteNumber = state.formValues.quoteNumber;
  const name = state.formValues.firstName;
  const encryptedQuoteID = state.formValues.encryptedQuoteID;

  if (typeof window !== 'undefined') {
    window.onbeforeunload = null;
  }
  return (
    <>
      {quoteStatus === SALESFORCE_STATUS.QuoteOffered && (
        <PageTracking
          path={`/${ANALYTICS_SITESUBSECTION}/quote-success-gem`}
          exceptions={tealiumConfig.pageDataExceptions}
        >
          <NonFormLayout
            product={productGem}
            containerProps={{ 'margin-lg': '10 0 0 0' }}
          >
            <ApprovedMessage
              interestRate={interestRate}
              securedInterestRate={securedInterestRate}
              quoteNumber={quoteNumber}
              encryptedQuoteID={encryptedQuoteID}
              name={name}
              queryString={queryString}
              isGem
            />
          </NonFormLayout>
        </PageTracking>
      )}
      {(quoteStatus === SALESFORCE_STATUS.Referred ||
        quoteStatus === SALESFORCE_STATUS.ServiceUnavailable ||
        quoteStatus === SALESFORCE_STATUS.InitialSubmit ||
        quoteStatus === null) && (
        <PageTracking
          path={`/${ANALYTICS_SITESUBSECTION}/quote-referred-gem`}
          exceptions={tealiumConfig.pageDataExceptions}
        >
          <NonFormLayout product={productGem}>
            <ReferredMessage name={name} isGem />
          </NonFormLayout>
        </PageTracking>
      )}
      {quoteStatus === SALESFORCE_STATUS.Declined && (
        <PageTracking
          path={`/${ANALYTICS_SITESUBSECTION}/quote-declined-gem`}
          exceptions={tealiumConfig.pageDataExceptions}
        >
          <>
            <SoftQuoteHeader product={productGem} />
            <SectionLayout>
              <QuoteDeclinedSection userData={{ firstName: name }} isGem />
            </SectionLayout>
            <SimplifiedFooter />
          </>
        </PageTracking>
      )}
    </>
  );
}
